body {
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media(width<800px){
  img{
    height:350px
  }
}

@media(width<300px){
  img{
    height:200px
  }
}

@media(width>800px){
  img{
    height:700px
  }
}

@media(width>1000px){
  img{
    height:900px
  }
}






